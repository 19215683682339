"use client"
import { keys } from "@/lib/utils";
import { APIProvider } from "@vis.gl/react-google-maps";

function GoogleMapProvider({ children }) {
    return (
        <APIProvider apiKey={keys.google.mapsKey}>
            {children}
        </APIProvider>
    );
}

export default GoogleMapProvider;
